export function getToken() {
    return sessionStorage.getItem("token");
}

export function getRefreshToken() {
    return sessionStorage.getItem("refresh");
}

export function getIpLocal() {
    return sessionStorage.getItem("ip_local");
}

export function getIpPublic() {
    return sessionStorage.getItem("ip_public");
}

export function getAuthorizationToken() {
    return `${getToken()}|${getIpPublic()}|${getIpLocal()}`;
}
