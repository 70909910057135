<template>
  <v-app-bar flat app rounded="0" height="60" id="iscNavbar" class="">
    <div
      class="d-flex align-center justify-space-between"
      style="width: calc(100% - 60px)"
    >
      <div>
        <div class="ml-6">
          <nav class="mainNav d-flex justify-space-around">
            <a
                href="#"
                @click.prevent="navigate('listen')"
                :class="module == 'listen' ? 'navSelected' : ''"
            >LISTEN</a>
            <a
                href="#"
                @click.prevent="navigate('monitor')"
                :class="module == 'monitor' ? 'navSelected' : ''"
            >MONITOR</a>
            <a
                href="#"
                @click.prevent="navigate('design')"
                :class="module == 'design' ? 'navSelected' : ''"
            >DESIGN</a>
            <a
                href="#"
                @click.prevent="navigate('advise')"
                :class="module == 'advise' ? 'navSelected' : ''"
            >ADVISE</a>
            <a
                v-if="isBackOfficeVisible"
                href="#"
                @click.prevent="navigate('backoffice')"
                :class="module == 'backoffice' ? 'navSelected' : ''"
            >BACKOFFICE</a>
          </nav>
        </div>
      </div>
      <div class="d-flex align-center justify-center">
        <div class="d-flex userText d-flex">
          <span class="d-flex">
            Welcome&nbsp;,&nbsp;<b>{{ nombreUsuario }}</b>
            <b> ID: {{ userGroupName }}</b>
          </span>
        </div>
      </div>
    </div>
    <div class="userImage d-flex align-center justify-center mx-3">
      <img
        @click="showModal = true"
        class="profile_img"
        :src="url"
        alt="foto de perfil"
      />
    </div>
    <div class="userSettings d-flex align-center justify-center">
      <div class="button-config" @click="toggleDropdown">
        <i class="fa fa-cog fa-2x"></i>
      </div>
    </div>
    <div class="dropdown-core" v-show="openedDropdown">
      <a :href="link.url" v-for="(link, index) in links" :key="index">
        <i :class="link.icon"></i> <span>{{ link.label }}</span>
      </a>
      <a @click="Logout" style="cursor: pointer">
        <i class="fa fa-power-off"></i> <span>Logout</span>
      </a>
    </div>
    <v-dialog
      v-model="showModal"
      width="500px"
      content-class="dialogImage"
      v-on:click:outside="closeImageModal"
    >
      <v-card width="500px">
        <div class="d-flex justify-space-between">
          <div>
            <v-card-title style="font-size: 20px"> Upload Logo </v-card-title>
          </div>
          <div class="pt-3 pr-3">
            <v-btn icon dark style="color: darkgray" @click="closeImageModal">
              <v-icon size="20">mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <v-divider light></v-divider>
        <div
          class="d-flex flex-column justify-center align-center"
          style="padding: 1rem"
        >
          <div>
            <img
              @click="showModal = true"
              class="profile_img_modal mt-5"
              :src="url"
              alt="foto de perfil"
            />
          </div>
          <div class="d-flex flex-column" style="width: 60%">
            <div class="align-self-start">
              <p class="mb-0" style="font-size: 17px; text-align: center">
                Select File
              </p>
            </div>
            <div class="align-self-start mb-10">
              <input
                type="file"
                @change="uploadFile"
                ref="inputProfileImage"
                accept="image/*"
                style="font-size: 13px"
              />
              <span class="mb-4" style="color: red; font-size: 14px">
                {{ textoValidacion }}
              </span>
            </div>
          </div>
          <!-- <div class="">
            <p class="mb-4 " style="font-size: 17px">Select file</p>
            <input
              type="file"
              class="mb-12"
              @change="uploadFile"
              ref="inputProfileImage"
            />
            <p class="text-xs mb-4" style="color: red">
              {{ textoValidacion }}
            </p>
          </div> -->
        </div>
        <v-divider light></v-divider>
        <div class="d-flex justify-end pa-4">
          <v-btn
            depressed
            light
            style="margin-right: 7px"
            @click="closeImageModal"
            >Close</v-btn
          >
          <v-btn
            depressed
            class="btnUploadFile"
            @click="updateImageProfile"
            :disabled="inputDisabled"
            >Upload File</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import ip from "ip";
import Swal from "sweetalert2";
const publicIp = require("what-is-my-ip-address");
const imageLocalProfileKey = "navbar/image-profile";
import { getAuthorizationToken, getRefreshToken, getToken } from '@/services/security';

const resourseIsClientOrUsuarioKey = "navbar/is-client-or-usuario";
const userGroupNameKey = "navbar/user-group-name";
export default {
  name: "Header",
  data() {
    return {
      httpEndpoint: process.env.VUE_APP_API_BACKOFFICE_URL,
      isBackOfficeVisible: false,
      url: !!sessionStorage.getItem(imageLocalProfileKey)
        ? sessionStorage.getItem(imageLocalProfileKey)
        : require("@/assets/icons/usuario-desconocido.jpg"),
      links: [
        {
          icon: "fa fa-user",
          url: `${process.env.VUE_APP_MONITOR_URL}/settings/profile`,
          label: "Profile",
        },
        {
          icon: "fa fa-cog",
          url: `${process.env.VUE_APP_MONITOR_URL}/settings/settings`,
          label: "Settings",
        },
        {
          icon: "fa fa-th-large",
          url: `${process.env.VUE_APP_MONITOR_URL}/settings/quickstar`,
          label: "Quick Start",
        },
      ],
      nombreUsuario: "User",
      showModal: false,
      openedDropdown: false,
      inputDisabled: true,
      textoValidacion: "",
      urlAnterior: null,
      objUsuario: {},
      token: null,
      refresh: null,
      module: null,
      username: null,
      initWidth: null,
      userGroupName: !!sessionStorage.getItem(userGroupNameKey)
        ? sessionStorage.getItem(userGroupNameKey)
        : "ID",
    };
  },
  computed: {
  },
  methods: {
    navigate(targetModule) {
      const moduleUrls = {
        listen: process.env.VUE_APP_LISTEN_URL,
        monitor: process.env.VUE_APP_MONITOR_URL,
        design: process.env.VUE_APP_DESIGN_URL,
        advise: process.env.VUE_APP_ADVISE_URL,
        backoffice: process.env.VUE_APP_BACKOFFICE_URL,
      };

      const baseUrl = moduleUrls[targetModule];

      if (!baseUrl) {
        console.error(`Módulo "${targetModule}" no reconocido.`);
        return;
      }

      const token = getToken();
      const refreshToken = getRefreshToken();
      window.location.href = `${baseUrl}/auth?token=${token}&refresh=${refreshToken}`;
    },
    async queryPetition(endpoint, query, variables, operationName) {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          api_key: "a6e71e02cd9c6565c2ad2fbaf135bf64",
        },
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify({
          operationName,
          query,
          variables: variables,
        }),
      });
      return response.json();
    },

    async isAdmin(cognitoGroup) {
      const query = `
        query isAdmin($cognitoGroup: String!) {
          isAdmin(cognitoGroup: $cognitoGroup)
        }
      `;
      const variables = {
        cognitoGroup: cognitoGroup,
      };

      try {
        const resultado = await this.queryPetition(
          this.httpEndpoint,
          query,
          variables,
          "isAdmin"
        );
        this.isBackOfficeVisible = resultado?.data?.isAdmin ?? false;
      } catch (error) {
        this.isBackOfficeVisible = false;
      }
    },

    getDimensions() {
      const resizeContent = document.getElementById("ResizeModal");
      this.initWidth = document.documentElement.clientWidth;
      this.initWidth < 1024
        ? (resizeContent.style.display = "flex")
        : (resizeContent.style.display = "none");
    },
    toggleDropdown() {
      this.openedDropdown = !this.openedDropdown;
    },
    hideDropdown() {
      this.openedDropdown ? (this.openedDropdown = false) : null;
    },
    Logout() {
      fetch(`${process.env.VUE_APP_MONITOR_API}/login/session/logout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorizationToken(),
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.info("logout success");
        })
        .catch(console.log);
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = `${process.env.VUE_APP_MONITOR_URL}/login`;
    },
    async uploadFile(evento) {
      this.textoValidacion = "";
      // console.log('hubo un cambio en el upload')
      // console.log('hubo un cambio en el upload event: ',evento.target.files[0])
      let arrFiles = Array.from(evento.target.files);

      const imageSize = arrFiles[0].size;
      if (imageSize > 200_000) {
        this.textoValidacion = "The maximum size of the image is 200 KB";
        this.inputDisabled = true;
        return;
      }

      // console.log('el arrFiles: ',arrFiles)
      let arrFilesBlob = [];
      let arrNombresBlob = [];
      arrFiles.forEach((item) => {
        arrNombresBlob.push(item["name"]);
        arrFilesBlob.push(this.handleFile(item));
      });
      // console.log('los arrNombresBlob : ',arrNombresBlob)
      const nombresSplit = arrNombresBlob[0].split(".");
      const extencion = nombresSplit[nombresSplit.length - 1].toLowerCase();
      if (
        !(
          extencion === "jpg" ||
          extencion === "jpeg" ||
          extencion === "png" ||
          extencion === "png"
        )
      ) {
        this.textoValidacion = "La imagen no tiene el formato correcto.";
        this.inputDisabled = true;
        return;
      } else {
        this.inputDisabled = false;
      }
      const respPromiseAll = await Promise.all(arrFilesBlob);
      this.url = respPromiseAll[0];
    },
    cancelarSubida() {
      if (this.urlAnterior) {
        this.url = this.urlAnterior;
      }
    },
    handleFile(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    async updateImageProfile() {
      try {
        Swal.fire({
          title: `Loading`,
          text: "Uploading Image!",
          buttonsStyling: false,
          confirmButtonClass: "btn btn-primary",
          showConfirmButton: false,
          closeModal: false,
          icon: "info",
        });
        if (this.textoValidacion !== "") {
          this.textoValidacion =
            "No se puede subir la imagen sin antes corregir el formato.";
          this.inputDisabled = true;
        }
        let dataUpdateProfile = {};
        dataUpdateProfile.ID_USUARIO = this.objUsuario.ID_USUARIO;
        dataUpdateProfile.dscImgProfile = this.url;
        const urlSplit = this.url.split("/");

        if (urlSplit[0] !== "data:image") {
          return;
        }

        const isClient = await this.isUserTypeClient();

        const monitorApi = new URL(`${process.env.VUE_APP_MONITOR_API}`);

        if (isClient) {
          monitorApi.pathname = `${monitorApi.pathname.replace(
            /\/+$/,
            ""
          )}/login/user/updateImageProfile`;
          const response = await fetch(monitorApi.href, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: getAuthorizationToken(),
            },
            body: JSON.stringify(dataUpdateProfile),
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.code === 0) {
                sessionStorage.setItem(imageLocalProfileKey, this.url);
                Swal.fire({
                  title: `Success`,
                  text: "Uploaded Image!",
                  buttonsStyling: false,
                  confirmButtonClass: "btn btn-primary",
                  icon: "success",
                  focusConfirm: false,
                }).then(() => {
                  this.urlAnterior = this.url;
                  this.closeOnlyImageModal();
                });
              }
              return response;
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }

        if (!isClient) {
          monitorApi.pathname = `${monitorApi.pathname.replace(
            /\/+$/,
            ""
          )}/login/usuario/update-image-profile`;
          const response = await fetch(monitorApi.href, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: getAuthorizationToken(),
            },
            body: JSON.stringify(dataUpdateProfile),
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.code === 0) {
                sessionStorage.setItem(imageLocalProfileKey, this.url);
                Swal.fire({
                  title: `Success`,
                  text: "Uploaded Image!",
                  buttonsStyling: false,
                  confirmButtonClass: "btn btn-primary",
                  icon: "success",
                  focusConfirm: false,
                }).then(() => {
                  this.urlAnterior = this.url;
                  this.closeOnlyImageModal();
                });
              }
              return response;
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      } catch (error) {
        Swal.fire({
          title: `Error`,
          text: "There was a problem uploading the image!",
          buttonsStyling: false,
          confirmButtonClass: "btn btn-primary",
          icon: "warning",
        });
      }
    },
    async isUserTypeClient() {
      const monitorApiUrl = new URL(`${process.env.VUE_APP_MONITOR_API}`);
      monitorApiUrl.pathname = `${monitorApiUrl.pathname.replace(
        /\/+$/,
        ""
      )}/login/monitor/is-client-or-usuario`;
      return await fetch(monitorApiUrl.href, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorizationToken(),
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.code === 0 || response.code === 1003) {
            return response.data.response.isClientOrUsuario;
          }
          throw new Error("Error al obtener el tipo de usuario");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    async getPublicIp() {
      return await publicIp.v4();
    },
    async getLocalIp() {
      return ip.address();
    },
    async clientOrUserResource() {
      const monitorApiUrl = new URL(`${process.env.VUE_APP_MONITOR_API}`);
      monitorApiUrl.pathname = `${monitorApiUrl.pathname.replace(
        /\/+$/,
        ""
      )}/login/monitor/is-client-or-usuario`;
      const response = await fetch(monitorApiUrl.href, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthorizationToken(),
        },
      });
      const data = await response.json();
      const isValidCode = [0, 1003].includes(data?.code);
      if (!isValidCode) {
        throw new Error("Error al obtener el tipo de usuario");
      }
      return {
        data: {
          message: data?.data?.message,
          response: {
            dscNames: data?.data?.response?.dscNames,
            idNameGroup: data?.data?.response?.idNameGroup,
            imgProfile: data?.data?.response?.imgProfile,
            isClientOrUsuario: data?.data?.response?.isClientOrUsuario,
            username: data?.data?.response?.username,
          },
        },
        code: data.code,
      };
    },
    async setProfileData() {
      try {
        const referer = document.referrer;
        let profileData = JSON.parse(
          sessionStorage.getItem(resourseIsClientOrUsuarioKey)
        );
        const hasProfileData = !!profileData;
        const hasReferer = !!referer;

        if (!hasProfileData) {
          profileData = (await this.clientOrUserResource()).data;
        }

        if (hasReferer) {
          profileData = (await this.clientOrUserResource()).data;
        }

        if (profileData?.response?.imgProfile) {
          sessionStorage.setItem(
            imageLocalProfileKey,
            profileData?.response?.imgProfile
          );
          this.url = profileData?.response?.imgProfile;
          this.urlAnterior = profileData?.response?.imgProfile;
        }

        if (profileData?.response?.dscNames) {
          this.nombreUsuario = profileData?.response?.dscNames;
        }

        if (profileData?.response?.idNameGroup) {
          sessionStorage.setItem(
            userGroupNameKey,
            profileData?.response?.idNameGroup
          );
          this.userGroupName = profileData?.response?.idNameGroup;
          await this.isAdmin(profileData?.response?.idNameGroup);
        }
      } catch (error) {
        console.error("Error setting user profile image:", error);
      }
    },
    resetModal() {
      this.textoValidacion = "";
    },
    closeOnlyImageModal() {
      this.inputDisabled = true;
      this.showModal = false;
      this.$refs.inputProfileImage.value = null;
    },
    closeImageModal() {
      this.inputDisabled = true;
      this.showModal = false;
      this.cancelarSubida();
      this.$refs.inputProfileImage.value = null;
    },
    async getObjUsuario() {
      if (sessionStorage.getItem("username")) {
        const settings = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            DSC_USERNAME: sessionStorage.getItem("username"),
          }),
        };
        try {
          const { obj } = await (
            await fetch(
              `${process.env.VUE_APP_GET_USERINFO}`, //"https://l70nzl4bbh.execute-api.us-east-1.amazonaws.com/prod/login/user/getUserbyId", //monitor.isocialcube.com
              settings
            )
          ).json();
          if (!obj) {
            throw new Error("Error al obtener info del usuario");
          } else {
            this.$store.dispatch("user/setObjUsuario", obj);
            this.objUsuario = obj;
            this.token = sessionStorage.getItem("token");
            this.refresh = sessionStorage.getItem("refresh");
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        if (this.username != null) {
          const settings = {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              DSC_USERNAME: this.username,
            }),
          };
          try {
            const { obj } = await (
              await fetch(
                url_user_info, //"https://l70nzl4bbh.execute-api.us-east-1.amazonaws.com/prod/login/user/getUserbyId", //monitor.isocialcube.com
                settings
              )
            ).json();
            if (!obj) {
              throw new Error("Error al obtener info del usuario");
            } else {
              this.$store.dispatch("user/setObjUsuario", obj);
              this.objUsuario = obj;
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    },
  },
  created() {
    this.getDimensions();
    window.addEventListener("resize", this.getDimensions);
  },
  async mounted() {
    window.addEventListener("tokens-changed", async (event) => {
      this.token = event.detail.token;
      this.refresh = event.detail.refresh;

      window.addEventListener("user-changed", async (event) => {
        this.nombreUsuario = event.detail.name;
        this.username = event.detail.username;
        this.module = event.detail.module;
        await this.getObjUsuario();
        await this.setProfileData();
      });
    });
    if (!this.username && sessionStorage.getItem("username")) {
      await this.getObjUsuario();
      await this.setProfileData();
    }
  },
  watch: {
    "this.$store.getters['user/getObjUsuario']": {
      immediate: true,
      handler: function () {
        const obj = this.$store.getters["user/getObjUsuario"];
        if (obj) {
          this.nombreUsuario = obj.DSC_NOMBRES;
        } else {
          this.nombreUsuario = sessionStorage.getItem("name");
        }
        this.module = sessionStorage.getItem("module");
      },
    }
  },
};
</script>

<style scoped>
/* .mainNav {
  line-height: 35px;
  text-align: center;
  color: white;
  font-weight: 600;
} */
#iscNavbar {
  width: calc(100% - 70px);
  background-color: #00a5ff;
  margin-left: 70px;
  font-family: "Oxygen", "Roboto";
}

#iscNavbar >>> .v-toolbar__content {
  padding: 0px !important;
}

/* Modules */

.mainNav a {
  font-size: 12px;
  line-height: 35px;
  padding: 0 20px;
  margin-right: 15px;
  border: 1px solid rgba(0, 0, 0, 0);
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  text-decoration: none;
}

.mainNav a:focus {
  outline: none;
}

.mainNav a:hover {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 3px;
  background-color: transparent;
  color: #ffffff;
}

.mainNav a.navSelected {
  border-radius: 3px;
  color: #00a5ff;
  background-color: white;
}

/* Profile Picture */
.userImage {
  height: 50px;
  width: 50px;
}

.profile_img {
  border-radius: 100%;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.userText {
  text-overflow: ellipsis;
}
.userText span {
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: white;
}

.userText span b:first-child {
  margin-right: 1rem;
}

.userSettings {
  width: 70px;
  height: 60px;
  background-color: #273444;
  height: 100%;
}

.button-config {
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button-config i {
  font-size: 25px;
  color: #96a0b8;
}

.dropdown-core {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 50;
  padding: 35px 0;
  top: 60px;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  background: #f1f1f1 url("../assets/icons/logofullv.png") no-repeat scroll
    177px 66px;
}

.dropdown-core a {
  display: block;
  font-size: 12px;
  text-align: left;
  padding: 10px 20px 20px 20px;
  color: #566b8a;
  transition: all ease 0.2s;
  text-decoration: none;
}

.dropdown-core a:hover {
  color: #00a5ff;
}

.dropdown-core a i {
  font-size: 16px;
  margin-right: 1rem;
}

.btnUploadFile {
  background-color: #00a5ff !important;
  color: white;
}

/* Modal */

.profile_img_modal {
  border-radius: 100%;
  width: 150px;
  height: 150px;
}

* >>> .dialogImage {
  align-self: flex-start;
}
</style>
